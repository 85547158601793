import { Header, ItemList, NarrowContainer } from '../../components';
import './About.scss';
import { PHOTOGRAPHY, SKILLS } from '../../config';


export function About() {
  return (
    <NarrowContainer>
      <Header title="About"/>
      <div className="About">
        <section>
          <h2>About</h2>

          <p>Student at{' '}
            <a href="https://ntnu.no" className="about-link ntnu-link" target="_blank"
               rel="noreferrer">NTNU</a>,
            pursuing a master's degree in informatics with specialization in databases and search</p>

          <p>System developer at{' '}
            <a href="https://opoint.com" className="about-link opoint-link" target="_blank"
               rel="noreferrer">Opoint</a>
          </p>

          <p>Volunteer web developer and former team lead at{' '}
            <a href="https://samfundet.no" className="about-link samfundet-link" target="_blank"
               rel="noreferrer">Samfundet</a>
          </p>

          <p>Hobbyist photographer</p>
        </section>
        <section>
          <h2>Photography</h2>

          <ItemList items={PHOTOGRAPHY}/>
        </section>
        <section>
          <h2>Technical skills</h2>

          <ItemList items={SKILLS}/>
        </section>
      </div>
    </NarrowContainer>
  );
}
